import { AccountMonthlyBalance, BankTransactionsByCategory } from "./bank.domain";

export interface BasiqUserCustomerMapping {
    Id: string;
    Reference: string; // ABN or ACN for company and firstname + lastname + dob for consumers
    BasiqUserId: string;
    AuthLinkStatus: 'COMPLETED' | 'CREATED' | 'PRE_INIT' | 'DELETED';
    Type: 'Business' | 'Consumer';
    CreatedTime: string;
    UpdatedTime: string;
}

export interface CreateBasiqUserResponse {
    type: string;
    id: string;
    email: string;
    mobile: string;
    // Add other properties as per the API response
}

export interface CreateBusinessUserPayload {
    email: string;
    mobile: string;
    firstName?: string;
    lastName?: string;
    abn: string;
}

export interface CreateNonBusinessUserPayload {
    email: string;
    mobile: string;
    firstName?: string;
    lastName?: string;
    middleName?: string
}

export interface AuthLinkResponse {
    type: 'auth_link';
    userId: string;
    mobile: string;
    expiresAt: string;
    links: {
        public: string;
        self: string;
    };
}

interface BasiqAccountList {
    type: string;
    data: BasiqAccount[];
    links: {
        self: string;
        transactions: string;
        connection: string;
        institution: string;
        statements: string[];
    };
}

export interface BasiqTimeFrameMetrics {
    totalCredit: number;
    totalDebit: number;
    monthlyAverageCredit: number;
    numberOfCredits: number;
    averageCreditAmount: number;
    medianCreditAmount: number;
    numberOfDebits: number;
}

export interface BasiqAccount {
    type: string;
    id: string;
    depositRate: string;
    lendingRate: string;
    bsb: string;
    unmaskedAccNum: string;
    creationDate: string;
    accountHolder: string;
    maskedNumber: string;
    accountOwnership: string;
    isOwned: string;
    amortisedLimit: string;
    bundleName: string;
    accountNo: string;
    availableFunds: string;
    balance: string;
    creditLimit: string;
    class: {
        type: string;
        product: string;
    };
    connection: string;
    currency: string;
    institution: string;
    institutionDetails?: BasiqInstitution;
    transactionCategories?: BankTransactionsByCategory[];
    monthlyBalance?: AccountMonthlyBalance;
    dayBalances?: DayBalance[];
    minBalance?: number;
    avgBalance?: number;
    daysInNegative?: number;
    lastUpdated: string;
    name: string;
    status: string;
    meta: Meta;
    transactionIntervals: {
        from: string;
        to: string;
    }[];
}

export type BasiqAccountWithMetrics = BasiqAccount & { allTimeMetrics: BasiqTimeFrameMetrics, last180DaysMetrics: BasiqTimeFrameMetrics; last90DaysMetrics: BasiqTimeFrameMetrics };

interface Meta {
    fees: Fee[];
    depositRates: DepositRate[];
    lendingRates: LendingRate[];
    termDeposit: TermDeposit[];
    loan: Loan;
    creditCard: CreditCard;
    addresses: Address[];
}

interface Fee {
    name: string;
    feeType: string;
    amount: string;
    balanceRate: string;
    transactionRate: string;
    accruedRate: string;
    accrualFrequency: string;
    currency: string;
    additionalValue: string;
    additionalInfo: string;
    additionalInfoUri: string;
    discounts: Discount;
}

interface Discount {
    description: string;
    discountType: string;
    amount: string;
    balanceRate: string;
    transactionRate: string;
    accruedRate: string;
    feeRate: string;
    additionalValue: string;
    additionalInfo: string;
    additionalInfoUri: string;
    eligibility: Eligibility;
}

interface Eligibility {
    discountEligibilityType: string;
    additionalValue: string;
    additionalInfo: string;
    additionalInfoUri: string;
}

interface DepositRate {
    depositRateType: string;
    rate: string;
    calculationFrequency: string;
    applicationFrequency: string;
    tiers: Tier;
    additionalValue: string;
    additionalInfo: string;
    additionalInfoUri: string;
}

interface LendingRate {
    lendingRateType: string;
    rate: string;
    comparisonRate: string;
    calculationFrequency: string;
    applicationFrequency: string;
    interestPaymentDue: string;
    repaymentType: string;
    loanPurpose: string;
    tiers: Tier;
    additionalValue: string;
    additionalInfo: string;
    additionalInfoUri: string;
}

interface TermDeposit {
    lodgementDate: string;
    maturityDate: string;
    maturityAmount: string;
    maturityCurrency: string;
    maturityInstructions: string;
}

interface Loan {
    startDate: string;
    endDate: string;
    repaymentType: string;
    loanAmount: string;
    loanCurrency: string;
    nextInstalmentDate: string;
    minInstalmentAmount: string;
    minInstalmentCurrency: string;
    maxRedraw: string;
    maxRedrawCurrency: string;
    minRedraw: string;
    minRedrawCurrency: string;
    offsetAccountEnabled: boolean;
    offsetAccountIds: string[];
    repaymentFrequency: string;
}

interface CreditCard {
    minPaymentAmount: string;
    paymentDueAmount: string;
    paymentCurrency: string;
    paymentDueDate: string;
}

interface Address {
    addressType: string;
    simple: SimpleAddress;
}

interface SimpleAddress {
    mailingName: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    postcode: string;
    city: string;
    state: string;
    country: string;
    paf: PAF;
}

interface PAF {
    dpid: string;
    thoroughfareNumber1: number;
    thoroughfareNumber1Suffix: string;
    thoroughfareNumber2: number;
    thoroughfareNumber2Suffix: string;
    flatUnitType: string;
    flatUnitNumber: string;
    floorLevelType: string;
    floorLevelNumber: string;
    lotNumber: string;
    buildingName1: string;
    buildingName2: string;
    streetName: string;
    streetType: string;
    streetSuffix: string;
    postalDeliveryType: string;
    postalDeliveryNumber: number;
    postalDeliveryNumberPrefix: string;
    postalDeliveryNumberSuffix: string;
    localityName: string;
    postcode: string;
    state: string;
}

interface Tier {
    name: string;
    unitOfMeasure: string;
    minimumValue: number;
    maximumValue: number;
    rateApplicationMethod: string;
    applicabilityConditions: ApplicabilityConditions;
    additionalInfo: string;
    additionalInfoUri: string;
}

interface ApplicabilityConditions {
    additionalInfo: string;
    additionalInfoUri: string;
}

export interface BasiqUser {
    type: string;
    id: string;
    email: string;
    mobile: string;
    firstName: string;
    lastName: string;
    middleName: string;
    businessName: string;
    businessIdNo: string;
    businessIdNoType: 'ABN' | 'ACN';
    verificationStatus: boolean;
    verificationDate: string;
    // Add other properties as needed
}

export interface BasiqTransaction {
    type: 'transaction';
    id: string;
    status: string;
    description: string;
    amount: string;
    account: string;
    balance: string;
    direction: 'debit' | 'credit';
    class: string;
    institution: string;
    connection: string;
    transactionDate: string;
    postDate: string;
    subClass: {
        title: string;
        code: string;
    };
    enrich: {
        cleanDescription: string;
        tags: string[];
        merchant: {
            id: string;
            businessName: string;
            website: string;
            abn: string;
            logoMaster: string;
            logoThumb: string;
            phoneNumber: {
                local: string;
                international: string;
            };
        };
        location: {
            routeNo: string;
            route: string;
            postalCode: string;
            suburb: string;
            state: string;
            country: string;
            formattedAddress: string;
            geometry: {
                lat: string;
                lng: string;
            };
        };
        category: {
            anzsic: {
                division: {
                    code: string;
                    title: string;
                };
                subdivision: {
                    code: string;
                    title: string;
                };
                group: {
                    code: string;
                    title: string;
                };
                class: {
                    code: string;
                    title: string;
                };
                subclass: {
                    code: string;
                    title: string;
                };
            };
        };
    };
    links: {
        self: string;
        account: string;
        institution: string;
        connection: string | null;
    };
}

export interface GetBasiqTransactionsResponse {
    type: string;
    count: number;
    size: number;
    data: BasiqTransaction[];
    links: {
        self: string;
        next?: string;
    };
}

export type BasiqTransactionsGroupByAccount = Record<string, BasiqTransaction[]>

export interface BasiqCompanyDetailsBody {
    email: string,
    mobile: string,
    firstName: string,
    middleName?: string,
    lastName: string,
    businessName: string,
    businessIdNo: string,
    businessIdNoType: 'ABN' | 'ACN',
    businessAddress: {
        addressLine1: string,
        suburb: string,
        state: string,
        postcode: string,
        countryCode: string
    },
    verificationStatus?: boolean,
    verificationDate?: string
}

export interface BasiqConsumerDetailsBody {
    email: string,
    mobile: string,
    firstName: string,
    middleName?: string,
    lastName: string,
    verificationStatus?: boolean,
    verificationDate?: string
}

export interface BasiqEventData {
    eventId: string,
    eventTypeId: "connection.created" | string,
    links: {
        event: string,
        eventEntity: string
    }
}

export interface BasiqEventDetails {
    type: string,
    id: string,
    createdDate: string,
    entity: "connection" | string,
    eventType: "created" | string,
    userId: string,
    dataRef: string,
    data: {
        createdDate: string,
        id: string,
        institution: {
            id: string,
            links: {
                self: string
            },
            type: "institution" | string
        },
        lastUsed: string,
        links: {
            accounts: string,
            self: string,
            transactions: string
        },
        mfaEnabled: false,
        status: "active" | "pre-init" | string,
        type: "connection" | string
    },
    links: {
        self: string
    }
}

export interface BasiqInstitution {
    type: string;
    id: string;
    name: string;
    shortName: string;
    institutionType: string;
    country: string;
    serviceName: string;
    serviceType: string;
    loginIdCaption: string;
    passwordCaption: string;
    tier: string;
    authorization: string;
    features: {
        login: string[];
        accounts: {
            accountNo: string[];
            name: string[];
            currency: string[];
            balance: string[];
            availableFunds: string[];
            lastUpdated: string[];
            accountHolder: string[];
            meta: string[];
        };
        transactions: {
            status: string[];
            description: string[];
            date: string[];
            amount: string[];
            balance: string[];
            class: string[];
        };
        profile: {
            fullName: string[];
            firstName: string[];
            lastName: string[];
            middleName: string[];
            phoneNumbers: string[];
            emailAddresses: string[];
            physicalAddresses: string[];
        };
    };
    forgottenPasswordUrl: string;
    stage: string;
    status: string;
    stats: any;
    logo: any;
    links: any;
}

export interface DayBalance {
    date: string;
    balance: string;
}

export interface BasiqTransactionCategory {
    amountcredit: number;
    amountdebit: number;
    avgcredit: number;
    avgdebit: number;
    category: string;
    id: string;
    numcredit: number;
    numdebit: number;
    transactions: BasiqTransaction[]
}

export interface BasiqRefreshResponse {
    type: 'list',
    data: {
        type: 'job' | string,
        id: string,
        links: any
    }[]
}

export interface BasiqReportResponse {
    id: string;
    status: string;
}

export type BasiqReportType = 'CON_AFFOR_01' | 'STATEMENT_01'; // Adjust based on available report types

export interface BasiqReportFilter {
    name: 'fromDate' | 'toDate' | 'accounts' | 'users' | 'includeMetrics' | 'includeGroups';
    value: any | string[];
}

export interface BankstatementAndBasiqDataStatus {
    hasBasiqUserId: boolean;
    hasBankStatement: boolean;
    hasBasiqStatement: boolean;
    SelectedProvider?: 'basiq' | 'illion';
    Status?: 'pending' | 'completed';
    UpdatedTime?: string;
}

export interface LatestBankStatementStatus {
    Id: number;
    Reference: string;
    SelectedProvider: 'basiq' | 'illion';
    Status: 'pending' | 'active' | 'deleted' | 'pre-init';
    CreatedTime: string;
    UpdatedTime: string;
}

export type BasiqReferenceType = 'Individual' | 'Business';

export interface BasiqEnrichTransactionResponse {
    type: 'enrich';
    direction: 'debit' | 'credit';
    class: string;
    data: {
        merchant: {
            id: string;
            businessName: string;
            website: string;
            abn: string;
            logoMaster: string;
            logoThumb: string;
            phoneNumber: {
                local: string;
                international: string;
            }
        };
        tags: string[];
        category: {
            matchType: string, matchScore: number, anzsic: {
                division: { code: string, title: string },
                subdivision: { code: string, title: string },
                group: { code: string, title: string },
                class: { code: string, title: string },
                subclass: { code: string, title: string }
            }
        };
        location: {
            routeNo: string;
            route: string;
            postalCode: string;
            suburb: string;
            state: string;
            country: string;
            formattedAddress: string;
            geometry: { lat: string, lng: string }
        } | null;
    }
    links: {
        self: string;
        "logo-master": string | null;
        "logo-thumb": string | null;
    }
}

export interface EnrichedTransaction {
    direction: 'debit' | 'credit';
    businessName: string;
    abn: string;
    phoneNumber: {
        local: string;
        international: string;
    }
    website: string | null;
    logoMaster: string | null;
    logoThumb: string | null;
    location: {
        routeNo: string;
        route: string;
        postalCode: string;
        suburb: string;
        state: string;
        country: string;
        formattedAddress: string;
        geometry: { lat: string, lng: string }
    } | null;
}
