import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FlexModule } from '@angular/flex-layout/flex';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DocumentTag, SelectableTagsDialogData, SelectableTagsDialogResult } from '@portal-workspace/grow-shared-library';
import { distinctUntilChanged, Subscription, tap } from 'rxjs';
import { ApplicationDialogService } from './application-dialog.service';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';

@UntilDestroy({arrayName: 'subscriptions'})
@Component({
    templateUrl: './selectable-tags.dialog.html',
    styleUrls: ['./selectable-tags.dialog.scss'],
    standalone: true,
    imports: [ MatButtonModule, MatDialogModule, MatChipsModule, FlexModule, MatCardModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule]
})

export class SelectableTagsDialog implements OnInit{

  formGroup: FormGroup<{
    search: FormControl<string | null>;
  }>;
  formControlSearch: FormControl<string | null>;

  allTags: DocumentTag[] = [];
  selectableTags: DocumentTag[] = [];
  selectedTags: DocumentTag[] = [];
  fileName?: string;

  MAX_TAG_ALLOWED = 10;
  showMaxTagErrorMessage = false;
  enableCustomTag?: boolean = false;
  enableNonStandardCondition?: boolean = false;
  subscriptions: Subscription[] = [];

  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SelectableTagsDialogData,
              private matDialogRef: MatDialogRef<SelectableTagsDialog, SelectableTagsDialogResult>,
              private formBuilder: FormBuilder,
              private dialogService: ApplicationDialogService) {

    this.allTags = data.allTags;
    this.selectableTags = data.allTags;
    this.enableCustomTag = data.enableCustomTag;
    this.enableNonStandardCondition = data.enableNonStandardCondition;
    this.fileName = data.fileName;
    this.MAX_TAG_ALLOWED = data.maxTags ?? 10;

    let previouslySelectedTags: any;
    if (typeof data.previouslySelectedTags?.[0] == "string") {
      previouslySelectedTags = data.previouslySelectedTags.map(tag => {return {value: tag}});
    } else {
      previouslySelectedTags = data.previouslySelectedTags ?? [];
    }

    for (const tagValue of previouslySelectedTags) {
      const tagObj = this.allTags.find(tag => tag.value === tagValue.value);
      if (tagObj) {
        this.selectedTags.push(tagObj);
      }
    }

    this.formControlSearch = this.formBuilder.control(null);
    this.formGroup = this.formBuilder.group({
      search: this.formControlSearch,
    });
  }

  ngOnInit(){
    this.allTags = this.allTags.sort((a, b) => a.type.localeCompare(b.type));
    this.filterFn(this.formControlSearch.value);
    this.subscriptions.push(this.formControlSearch.valueChanges.pipe(
      distinctUntilChanged(),
      tap(r => this.filterFn(r))
    ).subscribe());
  }

  filterFn (filterVal: string | null) {
    this.selectableTags = this.allTags.filter((t) => {
      return !this.selectedTags.map(tag => tag.value).includes(t.value) && 
            (filterVal === null || filterVal === "" || t.value.includes(filterVal))
    });
  }

  onCancel($event: MouseEvent) {
    this.matDialogRef.close({
      valid: false,
      selectedTags: this.selectedTags
    })
  }

  onSubmit($event: MouseEvent) {
    this.matDialogRef.close({
      valid: true,
      selectedTags: this.selectedTags
    });
  }

  remove(tag: DocumentTag): void {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);

      // remove max tag error message
      if (this.selectedTags.length < this.MAX_TAG_ALLOWED) {
        this.showMaxTagErrorMessage = false;
      }
    }
    this.filterFn(this.formControlSearch.value);
  }

  addTag(tag: DocumentTag) {
    // avoid duplicated tags
    const tagObj = this.selectedTags.find(t => t.value === tag.value);
    if (!tagObj) {
      if (this.selectedTags.length >= this.MAX_TAG_ALLOWED) {
        this.showMaxTagErrorMessage = true;
        return;
      }

      this.selectedTags.push(tag);
    }
    this.filterFn(this.formControlSearch.value);
  }

  addCustomTag(nonStandardCondition: boolean) {
    this.subscriptions.push(
      this.dialogService.openCustomDocumentTagDialog({ nonStandardCondition: nonStandardCondition }).afterClosed().pipe(
        tap(r => {
          if (r && r.readyForSubmission) {
            this.allTags.push(r.tag);
            this.filterFn(this.formControlSearch.value);
          }
        })
      ).subscribe()
    );
  }
}