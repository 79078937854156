import {Observable} from "rxjs";
import {ApiResponse} from "./types.domain";
import { NewBPayPaymentBody, NewDirectPaymentBody } from "./banking-service.domain";
import { CustomerAccessLevelAndRoleAccessValue } from "./component/customer-access-level-and-role-access-component.domain";

export type DisableOverdraftUserFn = (userId: number, pismoAccountNumber: number) => Observable<ApiResponse>;
export type EnableOverdraftUserFn = (userId: number, pismoAccountNumber: number,customerAccessLvel:CustomerAccessLevelAndRoleAccessValue) => Observable<ApiResponse>;

export type OverdraftUserPayee = BpayOverdraftUserPayee | BankOverdraftUserPayee | null;


export interface BpayOverdraftUserPayee {
    id: number
    type: 'bpay',
    userId: number,
    bpayBillerId: string,
    bpayCrn: string,
    bpayBillerName: string,
    isFirstPayment?: boolean,
    bankName?: string,
  }

  export interface BankOverdraftUserPayee {
    id: number
    type: 'bank',
    userId: number,
    bankBSB: string,
    bankAccountNumber: string,
    bankAccountName: string,
    isFirstPayment?: boolean,
    bankName?: string,
  }

  export interface BankTansferConfirmationData {
    accountTitle: string;
    bsb: string;
    accountNumber: string;
    amount: string;
    date: string;
    reference: string;
  }

  export interface BpayTransferConfirmationData {
    billerCode: string;
    crn: string;
    amount: string;
    date: string;
    reference: string;
  }

  export interface BankPayee {
      bankAccountName: string;
      bankAccountNumber: string;
      bankBSB: string;
      id: number;
      type: string;
      userId: string;
      date: string;
      isFirstPayment: boolean;
  }
  export interface BPayee {
    bpayBillerId: string;
    bpayBillerName: string;
    bpayCrn: string;
    id: number;
    type: string;
    userId: string;
    isFirstPayment: boolean;
  }
  export interface SearchOverdraftPayee {
    search: string,
    // type: 'bpay' | 'bank'
  }




  export type UpdateOverdraftUserPayeeInput = OverdraftUserPayee
