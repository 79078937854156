
<div class="selectable-tags-dialog">
  <mat-dialog-content class= 'max-height-100-vh'>
    @if (!!fileName) {
      <div class="row">
        <div class="col mat-h4-custom title">
          <span class="mdi mdi-file-document-outline cursor-pointer"></span> {{fileName}}
        </div>
      </div>
    }

    <div class="row separator-top">
      <div class="col selected-chips">
        <div class="title">Select those document types your file contains</div>
        <mat-card appearance="outlined" class="card separator-top">
          <mat-card-content>
            <mat-chip-listbox #chipList aria-label="Tag selection">
              @for (tag of selectedTags; track tag) {
                <mat-chip-option
                (removed)="remove(tag)">
                {{tag.type}}
                <button matChipRemove>
                  <span class="mdi mdi-close-circle-outline"></span>
                </button>
              </mat-chip-option>
            }
            </mat-chip-listbox>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    @if (showMaxTagErrorMessage) {
      <div class="row separator-top">
        <div class="col error-message">
          Maximum {{MAX_TAG_ALLOWED}} tags are allowed for each document.
        </div>
      </div>
    }
    <div class="separator-top">
      <mat-form-field class="search">
        <input matInput type="text" [formControl]="formControlSearch" placeholder="Search tags...">
          <span class="mdi mdi-magnify" matPrefix></span>
      </mat-form-field>
      <div class="all-chips">
        <mat-chip-listbox aria-label="Tag selection">
          @for (tag of selectableTags; track tag) {
            <mat-chip-option
              (click)="addTag(tag)">
              {{tag.type}}
            </mat-chip-option>
          }
          @if (enableCustomTag) {
            <mat-chip-option class="custom-chip" (click)="addCustomTag(false)">
              + Custom Tag
            </mat-chip-option>
          }
          @if (enableNonStandardCondition) {
            <mat-chip-option class="custom-chip" (click)="addCustomTag(true)">
              + Non-standard Condition
            </mat-chip-option>
          }
        </mat-chip-listbox>
      </div>
    </div>

    <div class="row separator-top">
      <div class="col" fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-stroked-button (click)="onCancel($event)">Cancel</button>
        @if (selectedTags) {
          <button mat-flat-button color="primary" (click)="onSubmit($event)">Save Documents</button>
        }
      </div>
    </div>
  </mat-dialog-content>
</div>  
  